<template>
  <GameLayout :name-game="$t('games.fool.name')">
    <div class="containerFormCreate">
        <form class="formCreate">
          <div class="formElement">
            <label class="btn-gradient-1" for="playerName">{{ $t('games.fool.playerName') }}</label>
            <input v-model="playerName" type="text" id="playerName" class="input-gradient">
          </div>
          <div class="btnDiv">
            <button :disabled="!isButtonActive" type="button" @click="joinRoom" class="btn-grad">{{ $t('games.battleSee.join') }}</button>
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </form>
      </div>
    </GameLayout>
  </template>
  
  <script setup>
  import axios from "axios";
  import { ref, computed } from "vue";
  import { useRouter, useRoute } from 'vue-router';
  import GameLayout from "../GameLayout.vue";
  import { url_serv_fool } from "@/link";
  
  const router = useRouter();
  const route = useRoute();
  
  const playerName = ref(localStorage.getItem('playerName') || '');
  const errorMessage = ref('');
  const isButtonActive = computed(() => {
    return playerName.value.trim().length > 0;
  });
  
  const joinRoom = async () => {
    try {
      const roomId = route.params.roomId;
      if (!roomId) {
        errorMessage.value = 'ID комнаты отсутствует.';
        return;
      }
  
      const response = await axios.post(`${url_serv_fool}/api/join-room/${roomId}`, { name: playerName.value });
      const { player_id: playerId, num_players: numPlayers } = response.data;
  
      localStorage.setItem('playerName', playerName.value);
      localStorage.setItem('fool_player_id', playerId);
      localStorage.setItem('fool_num_players', numPlayers);
  
      router.push({ name: 'FoolGameRoom', params: { roomId, playerId } });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        errorMessage.value = 'Комната не найдена.';
      } else if (error.response && error.response.status === 403) {
        errorMessage.value = 'Комната заполнена.';
      } else {
        console.error('Ошибка при присоединении к комнате:', error);
        errorMessage.value = 'Произошла ошибка при присоединении к комнате. Пожалуйста, попробуйте снова.';
      }
    }
  };
  </script>
  
  <style scoped>
  .error-message {
    color: red;
    margin-top: 10px;
  }
  </style>
  