<template>
  <GameLayout name-game="Игра">
    <div class="containerFormCreate">
      <div v-if="!gameStarted && players.length < requiredPlayers" class="waiting-room">
        <h2>Ожидание подключения игроков...</h2>
        <p>Игроков в комнате: {{ players.length }}/{{ requiredPlayers }}</p>
        <div class="players-container">
          <div v-for="player in players" :key="player.player_id" class="player">
            <img :src="player.player_photo || '/default-avatar.png'" :alt="player.player_name" class="player-avatar">
            <p>{{ player.player_name }}</p>
          </div>
        </div>
        <TelegramShareButton :url="url_share" text="Присоединяйся к игре!"></TelegramShareButton>
        <ShareButton :url="url_share"></ShareButton>
      </div>

      <div v-else-if="gameStarted" class="game-room">
        <h2>Игра началась!</h2>

        <div class="opponent-area">
          <div v-for="player in opponentPlayers" :key="player.player_id" class="opponent">
            <h3>{{ player.player_name }} ({{ player.hand_size }} карты)</h3>
            <div class="cards">
              <div v-for="index in player.hand_size" :key="index" class="card back"></div>
            </div>
          </div>
        </div>

        <div class="discard-pile">
          <h2>{{ deckSize }}</h2>
          <h3>Карта на отбое:</h3>
          <div v-if="discardPile.length > 0" class="card" :class="discardPile[0].color">
            <p>{{ discardPile[0].value }}</p>
          </div>
        </div>

        <p v-if="currentTurn">Сейчас ходит: {{ getPlayerNameById(currentTurn) }}</p>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

        <div class="player-hand">
          <h3>Ваши карты:</h3>
          <div class="cards">
            <div v-for="(card, index) in playerHand" :key="index" @click="makeMove(card)" class="card" :class="card.color">
              <p>{{ card.value }}</p>
            </div>
          </div>
        </div>

        <div v-if="drawCardAvailable" class="action-section">
          <button @click="drawCard" class="btn-grad">Взять карту</button>
        </div>
      </div>
    </div>
  </GameLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ShareButton from '@/components/ShareButton.vue';
import TelegramShareButton from '@/components/TelegramShareButton.vue';
import GameLayout from '../GameLayout.vue';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { url_serv_uno_wss, url_main_page } from "@/link";

const route = useRoute();
const router = useRouter();
const roomId = ref(route.params.roomId || localStorage.getItem('uno_room_id'));
let playerId = ref(route.params.playerId || localStorage.getItem('uno_player_id'));
let playerName = ref(localStorage.getItem('playerName') || 'Player');
const requiredPlayers = ref(parseInt(localStorage.getItem('uno_num_players') || '4'));

const players = ref([]);
const gameStarted = ref(false);
const playerHand = ref([]);
const discardPile = ref([]);
const currentTurn = ref('');
const errorMessage = ref('');
const drawCardAvailable = ref(false);
const deckSize = ref(0); // Инициализация переменной

const url_share = `${url_main_page}/uno/connect/${roomId.value}`;

let ws;

const opponentPlayers = ref([]);

// Подключение к WebSocket
const connectWebSocket = () => {
    const wsUrl = `${url_serv_uno_wss}/ws/${roomId.value}/${playerId.value}`;
    ws = new ReconnectingWebSocket(wsUrl, [], {
        maxRetries: 3,
        minReconnectionDelay: 2000,
    });

    ws.onopen = () => {
        console.log("WebSocket connection opened");
        // Отправляем запрос на обновление состояния игры
        ws.send(JSON.stringify({ action: 'get_room_state' }));
    };

    ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received WebSocket data:', data);

        if (data.type === 'room_state') {
            console.log('Room state received:', data);
            
            players.value = data.players || [];
            currentTurn.value = data.currentTurn || '';
            discardPile.value = data.discardPile || [];
            deckSize.value = data.deckSize || 0;
            playerHand.value = data.playerHand || []; // Добавляем логирование
            console.log('Player hand:', playerHand.value);

            updateOpponentPlayers();

            if (players.value.length >= requiredPlayers.value) {
                // Начинаем игру, только если все игроки подключены
                setTimeout(() => {
                    gameStarted.value = true;
                    playerHand.value = data.playerHand || [];
                    console.log('Game started after delay:', playerHand.value);
                }, 40); // Задержка в 4 секунды перед началом игры
            }
        } else if (data.type === 'game_started') {
            console.log('Game started for player:', playerId.value);
            
            setTimeout(() => {
                gameStarted.value = true;
                playerHand.value = data.playerHand || [];
                discardPile.value = data.discardPile || [];
                currentTurn.value = data.currentTurn || '';
                deckSize.value = data.deckSize || 0;
                console.log('Updated player hand after delay:', playerHand.value);
                updateOpponentPlayers();
            }, 40); // Задержка в 4 секунды перед отображением карт
        } else if (data.type === 'error') {
            errorMessage.value = data.message;
        }
    };

    ws.onclose = () => {
        console.log("WebSocket connection closed");
        setTimeout(() => {
            connectWebSocket();
        }, 1000);
    };
};

// Обновление информации об игроках
const updateOpponentPlayers = () => {
    opponentPlayers.value = players.value.filter(player => player.player_id !== playerId.value).map(player => ({
        player_id: player.player_id,
        player_name: player.player_name,
        hand_size: player.hand_size || 0,
    }));
};

// Логика хода игрока
const makeMove = (card) => {
    if (playerId.value !== currentTurn.value) {
        errorMessage.value = 'Сейчас не ваш ход';
        return;
    }

    console.log(`Player ${playerName.value} is trying to play card:`, card);

    ws.send(JSON.stringify({
        action: 'play_card',
        card: {
            color: card.color,
            value: card.value,
            type: card.type
        }
    }));

    drawCardAvailable.value = false;

    // Дополнительное обновление локального состояния для отображения изменений сразу
    discardPile.value.unshift(card);
    playerHand.value = playerHand.value.filter(c => !(c.color === card.color && c.value === card.value && c.type === card.type));
};

// Определение имени игрока по его ID
const getPlayerNameById = (id) => {
    const player = players.value.find(player => player.player_id === id);
    return player ? player.player_name : 'Unknown';
};

// Взятие карты из колоды
const drawCard = () => {
    if (playerId.value !== currentTurn.value) {
        errorMessage.value = 'Сейчас не ваш ход';
        return;
    }

    ws.send(JSON.stringify({ action: 'draw_card' }));
    drawCardAvailable.value = false;
};

onMounted(() => {
    connectWebSocket();
});
</script>

<style scoped>
.waiting-room, .game-room {
  text-align: center;
  margin-top: 20px;
}

.players-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-avatar {
  max-width: 100px;
  border-radius: 50%;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.card {
  width: 60px;
  height: 90px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 2px solid #000;
  font-size: 1.2rem;
}

.card.red { background-color: red; color: white; }
.card.yellow { background-color: yellow; color: black; }
.card.green { background-color: green; color: white; }
.card.blue { background-color: blue; color: white; }
.card.wild { background-color: black; color: white; }

.card.back {
  background-color: gray;
  border-color: gray;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.action-section {
  margin-top: 20px;
}

.opponent-area {
  margin-bottom: 20px;
}

.player-hand {
  margin-top: 20px;
}
</style>
