<template>
  <div class="container">
    <h1>Создание комнаты для игры Uno</h1>
    <form @submit.prevent="createRoom">
      <div class="form-group">
        <label for="playerName">Имя игрока:</label>
        <input v-model="playerName" type="text" id="playerName" required class="input-gradient" />
      </div>
      <div class="form-group">
        <label for="numPlayers">Количество игроков:</label>
        <input v-model.number="numPlayers" type="number" id="numPlayers" min="2" max="10" required class="input-gradient" />
      </div>
      <button type="submit" :disabled="!isFormValid" class="btn-grad">Создать комнату</button>
    </form>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { url_serv_uno } from "@/link";


const playerName = ref(localStorage.getItem('playerName') || '');
const numPlayers = ref(2);
const errorMessage = ref('');
const router = useRouter();

const isFormValid = computed(() => {
  return playerName.value.trim().length > 0 && numPlayers.value >= 2 && numPlayers.value <= 10;
});

const createRoom = async () => {
  if (!isFormValid.value) {
    errorMessage.value = 'Пожалуйста, введите корректные данные.';
    return;
  }

  try {
    // Отправка запроса на сервер для создания комнаты и генерации уникальных идентификаторов
    const response = await axios.post(`${url_serv_uno}/api/create-room`, {
      player_name: playerName.value,
      num_players: numPlayers.value
    });


    // Корректное получение roomId и playerId из ответа
    const { room_id: roomId, player_id: playerId } = response.data;

    // Сохранение данных в локальное хранилище с префиксом uno_
    localStorage.setItem('uno_room_id', roomId);
    localStorage.setItem('uno_player_id', playerId);
    localStorage.setItem('playerName', playerName.value);
    localStorage.setItem('uno_num_players', numPlayers.value);

    // Переход в компонент игры
    router.push({ name: 'UnoGameRoom', params: { roomId, playerId } }).then(() => {
      // window.location.reload(); // Обновляем страницу один раз после перенаправления
    });
  } catch (error) {
    console.error('Ошибка при создании комнаты:', error);
    errorMessage.value = 'Произошла ошибка при создании комнаты. Пожалуйста, попробуйте снова.';
  }
};
</script>

<style scoped>
.container {
  max-width: 500px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.input-gradient {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.btn-grad {
  padding: 10px 20px;
  background-color: #ff6f61;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}
</style>
