import { createApp } from 'vue';
import App from './App.vue';
import { router } from "./router";
import './assets/style.sass';
import './assets/neonCheckbox.scss';
import './assets/loader.css';
import store from './components/store';
import i18n, { setLocale, loadInitialLocales } from './i18n';
import { loadInitialAliasGameData, loadInitialSpyGameData } from './wordsStorage'; // Импорт функции для загрузки начальных игровых данных

// Добавляем обработку лоадера
const loaderElement = document.getElementById('loader');

let deferredPrompt; // Переменная для отложенной установки PWA

// Логика установки PWA
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;

  // Показать UI для установки (например, кнопка установки)
  const installButton = document.getElementById('install-button'); // Убедитесь, что у вас есть элемент с таким ID в разметке
  if (installButton) {
    installButton.style.display = 'block';

    installButton.addEventListener('click', () => {
      installButton.style.display = 'none'; // Скрыть кнопку после клика
      deferredPrompt.prompt(); // Показать диалог установки

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Пользователь принял установку');
        } else {
          console.log('Пользователь отклонил установку');
        }
        deferredPrompt = null;
      });
    });
  }
});

// Логика после установки PWA
window.addEventListener('appinstalled', () => {
  console.log('Приложение установлено!');
});

async function initApp() {
  await loadInitialLocales();
  await loadInitialAliasGameData();
  // await loadInitialSpyGameData();

  const app = createApp(App);

  // Применение языка при инициализации
  const savedLanguage = localStorage.getItem('language') || 'ua'; // По умолчанию 'ua', если нет сохраненного языка
  await setLocale(savedLanguage); // Установка сохраненного языка

  // Удаляем лоадер после загрузки
  if (loaderElement) {
    loaderElement.style.display = 'none';
  }

  app.use(router);
  app.use(store);
  app.use(i18n);

  app.mount('#app');
}

initApp(); // Инициализация приложения
