<template>
  <div class="mainContainer">
    <div class="header">
      <div class="text-gradient">Fizi</div>
      <div>(Fast and easy)</div>
      <div class="text-gradient">Good Games</div>
      <div>{{ $t('best_online_games') }}</div>
      <div class="locale-container">
        <select v-model="currentLocale" @change="changeLocale" class="locale-selector">
          <option value="ua">Ua</option>
          <option value="ru">Ru</option>
          <option value="en">En</option>
          <option value="pl">Pl</option>
          <option value="es">Es</option>
        </select>
      </div>
    </div>

    <hr>
    <br>
    
    <div class="filter">
      <label>{{ $t('players_count') }}</label>
      <input @focus="selectAll"  v-model.number="playersCount" type="number" min="2" max="20" placeholder="Сколько у вас игроков?" class="filter-input">
    </div>
    
    
    
    <div class="name_game" v-for="game in filteredGames" :key="game.key">
      <a :href="`${url_main_page}${game.url}?locale=${currentLocale}`">
        <h1>
          <i :class="game.icon"></i>
          <div class="game-name">{{ $t(`games.${game.key}.name`) }}</div>
        </h1>
        <div class="players-info">
          
         <div class="count-info" v-if="game.minPlayers !== game.maxPlayers">   {{ game.minPlayers }} - {{ game.maxPlayers }} <i class="fa-thin fa-person"></i> </div>
         <div class="count-info" v-else>{{ game.minPlayers }} <i class="fa-thin fa-person"></i>   </div>

        </div>
      </a>
      <button @click="openModal(game)" class="info-button">
        <i class="fa-thin fa-circle-info"></i> 
      </button>
    </div>
    
    <br>
    <ModalMain v-if="selectedGame" :game="selectedGame" @close="selectedGame = null" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ModalMain from '@/components/ModalMain.vue';

import {url_main_page} from "@/link"
import { setLocale } from '@/i18n';

const selectAll = (event) => {
  event.target.select(); // Выделяем весь текст при фокусе на инпуте
};

const games = ref([
  {
    key: 'fool',
    url: '/fool',
    icon: 'fa-sharp fa-thin fa-cards',  
    minPlayers: 2,
    maxPlayers: 6,
  },
  {
    key: 'alias',
    url: '/alias',
    icon: 'fa-thin fa-brain-circuit',   
    minPlayers: 3,
    maxPlayers: 12,
  },
  {
    key: 'codenames',
    url: '/codenames',
    icon: 'fa-thin fa-sitemap',
    minPlayers: 4,
    maxPlayers: 20,
  },
  {
    key: 'battleSee',
    url: '/battle-sea',
    icon: 'fa-thin fa-ship',
    minPlayers: 2,
    maxPlayers: 2,
  },
  {
    key: 'loseFriends',
    url: '/bye-friends',
    icon: 'fa-thin fa-users-slash',  
    minPlayers: 3,
    maxPlayers: 12,
  },
  {
    key: 'spy',
    url: '/spy/createRoom',
    icon: 'fa-thin fa-user-secret',
    minPlayers: 3,
    maxPlayers: 12,
  },
  {
    key: 'five_second',
    url: '/five-second',
    icon: 'fa-thin fa-stopwatch',
    minPlayers: 2,
    maxPlayers: 12,
  },
  {
    key: 'tod',
    url: '/tod',
    icon: 'fa-thin fa-hat-wizard',
    minPlayers: 2,
    maxPlayers: 20,
  }
]);

const selectedGame = ref(null);
const playersCount = ref(0);
const { locale } = useI18n();
const currentLocale = ref(locale.value);

const openModal = (game) => {
  selectedGame.value = game;
};

// Фильтрация игр по количеству игроков
const filteredGames = computed(() => {
  return games.value.filter(game => {
    if (playersCount.value === 0) return true; // если нет фильтра, показываем все игры
    return playersCount.value >= game.minPlayers && playersCount.value <= game.maxPlayers;
  });
});

const changeLocale = async (event) => {
  const lang = event.target.value;
  await setLocale(lang); // Используем централизованную функцию смены языка
};

onMounted(() => {
  const savedLanguage = localStorage.getItem('language') || 'ua';
  currentLocale.value = savedLanguage;
  locale.value = savedLanguage;
});
</script>

<style lang="sass">
.mainContainer
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  padding: 20px
  background: var(--bg-color) !important
  color: var(--text-color) !important
  margin-bottom: 50px
.header
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  position: relative

.locale-container
  margin-top: 10px

.locale-selector
  padding: 5px
  border-radius: 5px
  border: 1px solid var(--border-color) !important
  background: transparent !important
  color: var(--text-color) !important
  font-size: 16px
  cursor: pointer

.name_game
  border: 1px solid var(--border-color) !important
  padding: 20px
  border-radius: 12px
  margin: 20px
  text-decoration: none !important
  width: 80%
  max-width: 600px
  transition: transform 0.3s ease, box-shadow 0.3s ease
  display: flex
  justify-content: space-between
  align-items: center
  color: var(--text-color) !important
  box-shadow: 0 4px 8px var(--box-shadow-color)
  position: relative // Добавляем относительное позиционирование для родителя, чтобы блок количества игроков был привязан к нему
  z-index: 1
  max-width: 400px


.players-info
  background-color: var(--bg-color)
  border: 1px solid var(--border-color)
  border-radius: 10px
  padding: 5px 10px
  text-align: center
  color: var(--text-color) !important
  margin-bottom: 10px
  position: absolute // Абсолютное позиционирование
  top: -7px // Притягиваем к верхней части блока
  right: 2% // Притягиваем к правой части блока
  z-index: 9 // Придаем высокий приоритет отображения
  width: 100px
  padding: 5px
  background: var(--bg-color)
  box-shadow: 0 4px 8px var(--box-shadow-color)


.name_game a
  text-decoration: none !important
  color: inherit !important
  display: flex
  align-items: center
  flex: 1

.name_game h1
  display: flex
  align-items: center
  gap: 10px
  font-size: 1.5em
  width: 100%
  margin: 0 !important
  color: var(--text-color) !important

.name_game i
  font-size: 1.5em
  flex: 0 0 20%
  text-align: center
  color: var(--text-color) !important

.game-name
  flex: 1
  text-align: left
  color: var(--text-color) !important
  padding: 10px

.name_game:hover
  transform: scale(1.05)
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)

.info-button
  background: none
  border: none
  cursor: pointer
  font-size: 1.2em
  color: inherit
  margin-top: 25px !important
  
.menuBurger
  background: none
  border: none
  font-size: 24px
  cursor: pointer
  color: var(--burger-color) !important

@mixin gradient-text
  background: linear-gradient(45deg, #ff6b6b, #f06595, #cc5de8, #845ef7, #5c7cfa, #339af0, #22b8cf, #20c997, #51cf66, #94d82d, #fcc419, #ff922b, #ff6b6b)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  background-clip: text
  text-fill-color: transparent

.text-gradient
  @include gradient-text
  font-size: 3em
  font-weight: 700
  margin: 0 auto


.filter
  display: flex
  justify-content: center
  align-items: baseline

.filter-input
  width: 50px
  height: 30px
  margin-bottom: 20px
  border: 1px solid
  border-radius: 12px
  text-align: center
  padding: 0
  margin-left: 25px 
  background-color: #fff
  color: #000

.count-info  
  display: flex
  justify-content: space-evenly
  align-items: center
</style>
