<template>
  <GameLayout :name-game="$t('games.fool.name')">
    <div class="containerFormCreate">
      <!-- Форма для создания новой комнаты -->
      <form @submit.prevent="createRoom" class="formCreate">
        <div class="formElement">
          <label for="playerName">{{ $t('games.fool.playerName') }}:</label>
          <input v-model="playerName" type="text" id="playerName" required class="input-gradient" />
        </div>
        <div class="formElement">
          <label for="numPlayers">{{ $t('games.fool.numPlayers') }}:</label>
          <input v-model.number="numPlayers" type="number" id="numPlayers" min="2" max="6" required class="input-gradient" />
        </div>
        <button type="submit" :disabled="!isFormValid" class="btn-grad">{{ $t('games.fool.createRoom') }}</button>
      </form>

      <!-- Ошибка, если что-то пошло не так -->
      <div v-if="errorMessage" class="error-message">
        {{ $t('games.fool.errorMessage') }}
      </div>

      <!-- Кнопка для продолжения игры, если данные существуют -->
      <div v-if="canContinueGame" class="continue-game">
        <button @click="continueGame" class="btn-grad">{{ $t('games.fool.continueGame') }}</button>
      </div>
    </div>
  </GameLayout>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { url_serv_fool, url_stat } from "@/link";
import GameLayout from '../GameLayout.vue';

const playerName = ref(localStorage.getItem('playerName') || '');
const numPlayers = ref(2);
const errorMessage = ref('');
const router = useRouter();
const canContinueGame = ref(false);

const isFormValid = computed(() => {
  return playerName.value.trim().length > 0 && numPlayers.value >= 2 && numPlayers.value <= 6;
});

// Проверка, можно ли продолжить игру
onMounted(async () => {
  const roomId = localStorage.getItem('fool_room_id');
  const playerId = localStorage.getItem('fool_player_id');

  console.log("roomId:", roomId, "playerId:", playerId);

  if (roomId && playerId) {
    try {
      // Запрос на сервер для проверки существования комнаты
      const response = await axios.get(`${url_serv_fool}/api/check-room/${roomId}`);
      console.log("Ответ сервера:", response.data);  // Логируем ответ сервера

      // Проверяем, если комната существует
      if (response.data.exists === true) {
        canContinueGame.value = true;  // Показываем кнопку продолжения игры
        console.log("Комната найдена, canContinueGame:", canContinueGame.value);
      } else {
        // Если комната не существует, удаляем данные из localStorage
        localStorage.removeItem('fool_room_id');
        localStorage.removeItem('fool_player_id');
        console.log('Комната не найдена, данные удалены из localStorage');
      }
    } catch (error) {
      console.error('Ошибка проверки существования комнаты:', error);
      localStorage.removeItem('fool_room_id');
      localStorage.removeItem('fool_player_id');
    }
  }
});


const savedLocale = localStorage.getItem('language') || 'ua';


// Функция для создания новой комнаты
const createRoom = async () => {
  if (!isFormValid.value) {
    errorMessage.value = 'Пожалуйста, введите корректные данные.';
    return;
  }

  try {
    const response = await axios.post(`${url_serv_fool}/api/create-room`, {
      player_name: playerName.value,
      num_players: numPlayers.value
    });

    const { room_id: roomId, player_id: playerId } = response.data;

    localStorage.setItem('fool_room_id', roomId);
    localStorage.setItem('fool_player_id', playerId);
    localStorage.setItem('playerName', playerName.value);
    localStorage.setItem('fool_num_players', numPlayers.value);

    axios.post(url_stat, {
      game_id: 67,
      room_number: roomId,
      creator_name: playerName.value,
      language: savedLocale,
      player_count: numPlayers.value,
      is_local: false,
           
    });

    router.push({ name: 'FoolGameRoom', params: { roomId, playerId } });
  } catch (error) {
    console.error('Ошибка при создании комнаты:', error);
    errorMessage.value = 'Произошла ошибка при создании комнаты. Пожалуйста, попробуйте снова.';
  }
};

// Функция для продолжения игры
const continueGame = () => {
  const roomId = localStorage.getItem('fool_room_id');
  const playerId = localStorage.getItem('fool_player_id');

  if (roomId && playerId) {
    router.push({ name: 'FoolGameRoom', params: { roomId, playerId } });
  } else {
    errorMessage.value = 'Нет сохраненных данных для продолжения игры.';
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 10px;
}

.continue-game {
  margin-top: 20px;
}
</style>
