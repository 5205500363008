<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TelegramShareButton from '@/components/TelegramShareButton.vue';
import GameLayout from '../GameLayout.vue';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { url_serv_fool_wss, url_main_page } from "@/link";

const route = useRoute();
const roomId = ref(route.params.roomId || localStorage.getItem('fool_room_id'));
const playerId = ref(route.params.playerId || localStorage.getItem('fool_player_id'));
let playerName = ref(localStorage.getItem('fool_playerName') || 'Player');
const requiredPlayers = ref(parseInt(localStorage.getItem('fool_num_players') || '4'));

const players = ref([]);
const gameStarted = ref(false);
const playerHand = ref([]);
const errorMessage = ref('');
const currentTurn = ref('');
const trumpCard = ref({});  // Хранит козырную карту
const originalTrumpCard = ref({});  // Сохраняет оригинальный козырь для отображения
const deckSize = ref(0);
const boardCards = ref([]);
const selectedCard = ref(null);  // Для хранения выбранной карты для отбивания
const selectedBoardCard = ref(null);  // Для хранения выбранной карты на доске
const nextPlayerId = ref(''); // ID следующего игрока, на кого идет ход
const attackerId = ref(''); // ID игрока, который атакует
const defenderId = ref(''); // ID игрока, который защищается
const canEndTurn = ref(false);  // Отображение кнопки "Отбой"
const maxThrowCount = ref(4);  // Ограничение на количество подброшенных карт до отбоя
const endTurnVotes = ref({});  // Голоса за конец хода
const discardCount = ref(0);

const playerHands = ref({}); // Данные по картам каждого игрока



const thrownCardsCount = ref(0); // Количество подброшенных карт
const playedCardsCount = ref(0); // Количество карт, которыми ходит игрок


const isDeckNull = ref(true)

const url_share = `${url_main_page}/fool/connect/${roomId.value}`;
const isObserver = ref(false);
const defenderCanChoose = ref(false);  // Отслеживаем, может ли защищающийся принять решение (отбой или забрать карты)

const defenderCanEndTurn = ref(false); // Отслеживаем, может ли защищающийся завершить раунд


const showModal = ref(false);
const modalMessage = ref('');


const isSpecialCase = ref(false)
const calcAngleForOpponentCards = (index, totalCards) => {
  if (isSpecialCase.value) {
    // Если срабатывает особый случай (например, козырь забирается), карты располагаются ровно
    return 0;
  }
  
  // Вычисляем угол, чтобы карты отображались веером
  const maxAngle = 30; // Максимальный угол разброса карт
  const step = totalCards > 1 ? maxAngle / (totalCards - 1) : 0;
  return -maxAngle / 2 + step * index;
};








// Функция для вычисления количества карт, которые можно подбросить
const maxCardsToThrow = computed(() => {
  // Считаем только подброшенные карты (те, которые находятся в стопке одни и еще не отбиты)
  const thrownCardsCount_ = boardCards.value.reduce((acc, pile) => {
    // Если в стопке только одна карта, это подброшенная карта
    const isThrownCard = pile.length === 1;
    return acc + (isThrownCard ? 1 : 0);
  }, 0);

  const thrownCardsCount_0 = boardCards.value.reduce((acc, pile) => {
       // Если в стопке только одна карта, это подброшенная карта
    const isThrownCard = pile.length === 1;
    return acc + (isThrownCard ? 1 : 0);
  }, 0);

  // Если discardCount == 0, можно подкидывать до 5 карт
  if (discardCount.value === 0) {
    console.log(maxThrowCount.value)
    console.log(thrownCardsCount)
      // Важно: не учитываем карты, которыми игрок отбивается
     

    return Math.max(0, maxThrowCount.value - thrownCardsCount?.value); // Максимум 5 карт для подбрасывания
  }

  // Если discardCount > 0, можно подкидывать не больше, чем количество карт у защищающегося
  const defenderHandSize = players.value.find(player => player.player_id === defenderId.value)?.hand_size || 0;
  console.log(defenderHandSize)
    console.log(thrownCardsCount)
  // Убедитесь, что мы не учитываем карты, которыми игрок отбивается
  return Math.max(0, defenderHandSize - thrownCardsCount_);
});

// Проверка, можно ли подкидывать карту
const ifcanPlayerThrowCard = computed(() => {
  // Можно подбрасывать, если количество карт для подбрасывания больше 0
  return maxCardsToThrow.value > 0;
});







// Функция для показа модального окна
const showModalWindow = () => {
  showModal.value = true;
  setTimeout(() => {
    showModal.value = false;
  }, 3000); // Модальное окно будет показано 3 секунды
};

// Вызов функции, когда игрок завершает игру
if (isObserver.value) {
  showModalWindow();
}

const showTemporaryModal = (message) => {
    modalMessage.value = message;
    showModal.value = true;
    setTimeout(() => {
        showModal.value = false;
    }, 3000);
};


// Вычисление имени текущего игрока
const currentTurnPlayerName = computed(() => {
  return getPlayerNameById(currentTurn.value);
});

// Вычисление, защищается ли текущий игрок
const isDefending = computed(() => {
  return playerId.value === defenderId.value && boardCards.value.length > 0;
});

// Проверка видимости кнопок действия
const actionButtonsVisible = computed(() => {
  return boardCards.value.length > 0;
});

// Символ масти для карты
const getSuitSymbol = (suit) => {
  const suits = {
      hearts: '♥',
      diamonds: '♦',
      clubs: '♣',
      spades: '♠',
  };
  return suits[suit] || '';
};

let ws;

const opponentPlayers = ref([]);

let showEndTurnOptions = ref(false);  // Флаг для отображения кнопок "Отбой" и "Забираю"

const playersEndTurnVotes = ref({});  // Хранение информации о голосах за отбой


// Получить имя игрока по ID
const getPlayerNameById = (id) => {
    const player = players.value.find(player => player.player_id === id);
    return player ? player.player_name : 'Unknown';
};

// Подключение к WebSocket
// Подключение к WebSocket
const connectWebSocket = () => {
    const wsUrl = `${url_serv_fool_wss}/ws/${roomId.value}/${playerId.value}`;
    ws = new ReconnectingWebSocket(wsUrl, [], {
        maxRetries: 3,
        minReconnectionDelay: 2000,
    });

    ws.onopen = () => {
        console.log("WebSocket connection opened");

        // Отправляем запрос на получение состояния комнаты
        ws.send(JSON.stringify({
            action: 'get_room_state',
            room_id: roomId.value 
        }));
    };

    ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received WebSocket data:', data);

        switch (data.type) {
            case 'players_info':
                // Если получили обновление информации об игроках
                console.log('Получена информация об игроках:', data.players);
                players.value = data.players;
               

                if(players.value.length < requiredPlayers.value){
                
                  gameStarted.value = false;
                }
                break;

            case 'player_out':
                showTemporaryModal(`${getPlayerNameById(data.player)} завершил игру`);
                gameStarted.value = false;
                if (data.player === playerId.value) {
                    isObserver.value = true; // Устанавливаем игрока как наблюдателя
                }
                break;

            case 'new_game':
                console.log('new game start!!!!');
                resetGameState(data);
                break;

            case 'defender_end_turn_options':
                defenderCanEndTurn.value = true;
                break;

            case 'room_state':
                updateRoomState(data);
                break;

            case 'error':
                errorMessage.value = data.message;
                console.error('Error received from server:', data.message);
                break;

            case 'game_over':
                console.log(`Игра окончена. Проигравший: ${data.loser}`);
                showTemporaryModal(`Игра окончена! Проигравший: ${getPlayerNameById(data.loser)}`);
                gameStarted.value = false;
                break;

            default:
                console.error('Неизвестный тип данных:', data);
        }
    };

    ws.onclose = () => {
        console.log("WebSocket connection closed");
        setTimeout(() => {
            connectWebSocket();
        }, 2000);  // Увеличьте тайм-аут перед повторным подключением
    };
};

// Функция сброса состояния игры
const resetGameState = (data) => {
    attackerId.value = data.attacker_id;
    defenderId.value = data.defender_id;
    isObserver.value = false;
    playerHand.value = [];
    boardCards.value = [];
    trumpCard.value = {};
    originalTrumpCard.value = {};
    deckSize.value = 0;
    canEndTurn.value = false;
    endTurnVotes.value = {};
    currentTurn.value = data.attacker_id;
    discardCount.value = 0;

    // Запрашиваем обновленное состояние игры
    ws.send(JSON.stringify({
        action: 'get_room_state',
        room_id: roomId.value  
    }));
};

// Функция обновления состояния комнаты
const updateRoomState = (data) => {
    // Проверяем наличие данных о игроках
    if (data && Array.isArray(data.players) && data.playerHands) {
        // Обновляем список игроков, исключая тех, у кого нет карт
        players.value = data.players.map(player => ({
            ...player,
            hand_size: (data.playerHands[player.player_id] || []).length
        })).filter(player => player.hand_size > 0);
    } else {
        console.error('Данные игроков отсутствуют или неверные', data);
        return; // Выходим, если данные некорректны
    }

    // Обновляем голоса за "Отбой"
    if (data.endTurnVotes) {
        players.value = players.value.map(player => ({
            ...player,
            endTurnVoted: data.endTurnVotes[player.player_id] || false
        }));
    }

    // Проверяем наличие карт у текущего игрока
    const currentPlayerHand = data.playerHands[playerId.value] || [];
    playerHand.value = [...currentPlayerHand];

    // Если у игрока нет карт, он становится наблюдателем
    if (currentPlayerHand.length === 0) {
        console.log("Игрок завершил игру и стал наблюдателем.");
        isObserver.value = true;
    } else {
        sortPlayerHand(); // Сортировка карт игрока
    }

    // Обновляем состояние игры
    deckSize.value = (data.deckSize || 0) + 1;
    boardCards.value = [...(data.boardCards || [])];
    currentTurn.value = data.currentTurn || '';
    attackerId.value = data.attacker_id || '';
    defenderId.value = data.defender_id || '';
    canEndTurn.value = data.canEndTurn || false;
    isDefending.value = (data.defender_id === playerId.value);
    defenderCanEndTurn.value = false;
    playersEndTurnVotes.value = { ...data.endTurnVotes }; // Клонируем объект
    discardCount.value = data.discardCount || 0;
    thrownCardsCount.value = data.thrown_cards_count || 0;
    playedCardsCount.value = data.played_cards_count || 0;

    console.log(thrownCardsCount.value);

    // Обработка козырной карты
    handleTrumpCardUpdate(data);

    // Обновление данных об игроках соперников
    updateOpponentPlayers();

    // Если игра началась, устанавливаем флаг
    if (players.value.length >= requiredPlayers.value) {
        setTimeout(() => {
            gameStarted.value = true;
        }, 40);
    }
};



const handleTrumpCardUpdate = (data) => {
    if (data.trump_card && data.trump_card.suit && data.trump_card.value) {
        if (!originalTrumpCard.value.suit || !originalTrumpCard.value.value) {
            originalTrumpCard.value = { ...data.trump_card };
            console.log("Оригинальный козырь сохранен:", originalTrumpCard.value);
        }
        trumpCard.value = { ...data.trump_card }; // Клонируем объект козырной карты
        console.log("Козырь обновлен:", trumpCard.value);
    } else if (deckSize.value === 0 && originalTrumpCard.value.suit && originalTrumpCard.value.value) {
        trumpCard.value = { ...originalTrumpCard.value };
        console.log("Колода пуста, используем оригинальный козырь:", originalTrumpCard.value);
    } else if (!data.trump_card.suit && !data.trump_card.value && data.stored_trump) {
        trumpCard.value = { ...data.stored_trump };  // Клонируем объект козырной карты
        deckSize.value = 0;
        isSpecialCase.value = true;
        console.log("Козырная карта отсутствует, используем сохранённый козырь:", data.stored_trump);
    } else {
        console.log("Не удалось обновить козырь. Проверьте данные:", data);
    }
};



// Обновление списка оппонентов
const updateOpponentPlayers = () => {
    opponentPlayers.value = players.value
        .filter(player => player.player_id !== playerId.value && player.hand_size > 0)
        .map(player => ({
            player_id: player.player_id,
            player_name: player.player_name,
            hand_size: player.hand_size,
        }));
};



// Сделать ход
const makeMove = (card) => {

  if (playerHand.value.length === 0) {
    errorMessage.value = 'У вас нет карт для хода.';
    return;
  }

    if (!gameStarted.value) {
        errorMessage.value = 'Игра еще не началась.';
        return;
    }

    if (isObserver.value) {
        errorMessage.value = 'Вы уже выбыли из игры.';
        return;
    }

    // Если игрок не атакующий и на доске нет карт, запрещаем ход
    if (playerId.value !== attackerId.value && boardCards.value.length === 0) {
        errorMessage.value = 'Только атакующий игрок может положить первую карту.';
        return;
    }

    if (playerId.value !== currentTurn.value && !canPlayerThrowCard()) {
        errorMessage.value = 'Сейчас не ваш ход или вы не можете подбросить эту карту.';
        return;
    }

      // Проверяем, можно ли подкинуть карту
  if (!ifcanPlayerThrowCard.value) {
    errorMessage.value = 'Вы не можете подбросить эту карту.';
    return;
  }

    if (!canThrowCard(card)) {
        errorMessage.value = 'Вы не можете подбросить эту карту.';
        return;
    }

    console.log(`Player ${playerName.value} is trying to play card:`, card);

    ws.send(JSON.stringify({
        action: 'play_card',
        card: {
            suit: card.suit,
            value: card.value
        }
    }));

    boardCards.value.push([card]);
    playerHand.value = playerHand.value.filter(c => !(c.suit === card.suit && c.value === card.value));

   };


// Получить ID следующего игрока
const getNextPlayerID = (players, currentID) => {
  // Логируем список всех игроков с их картами
  players.forEach(player => {
    console.log(`Игрок: ${player.player_name}, ID: ${player.player_id}, Количество карт: ${player.hand_size}`);
  });

  // Получаем все player_id, включая текущего игрока
  const playerIDs = players.map(player => player.player_id);

  console.log('Отфильтрованные playerIDs:', playerIDs);
  const currentIndex = playerIDs.indexOf(currentID);
  console.log('Текущий индекс:', currentIndex);

  if (currentIndex === -1) {
    console.log('Текущий игрок не найден');
    return null;
  }

  // Получаем индекс следующего игрока
  const nextIndex = (currentIndex + 1) % playerIDs.length;
  console.log('Следующий индекс:', nextIndex, 'Next Player ID:', playerIDs[nextIndex]);
  return playerIDs[nextIndex];
};



// Выбор карты для отбивания
const selectCardToBeat = (card) => {
    if (!isDefending.value) return;

    selectedCard.value = card;
};

// Выбор карты на доске для отбивания
const selectBoardCardToBeat = (card) => {
    if (!selectedCard.value) return;

    selectedBoardCard.value = card;

    if (canBeat(selectedCard.value, selectedBoardCard.value)) {
        beatCard(selectedCard.value, selectedBoardCard.value);
    } else {
        errorMessage.value = 'Вы не можете побить эту карту.';
    }
};

// Отбивание карты
const beatCard = (card, boardCard) => {
    ws.send(JSON.stringify({
        action: 'beat_card',
        card: {
            suit: card.suit,
            value: card.value
        },
        boardCard: {
            suit: boardCard.suit,
            value: boardCard.value
        }
    }));

    boardCards.value = boardCards.value.map(pile => {
        if (pile[0].suit === boardCard.suit && pile[0].value === boardCard.value) {
            return [...pile, card];
        }
        return pile;
    });

    playerHand.value = playerHand.value.filter(c => !(c.suit === card.suit && c.value === card.value));
    selectedCard.value = null;
    selectedBoardCard.value = null;

   

    resetSelections();
};

// Сбросить выборы
const resetSelections = () => {
    selectedCard.value = null;
    selectedBoardCard.value = null;
};

// Функция для "Отбой"
const endTurn = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
            action: 'end_turn',
        }));
    } else {
        console.error('WebSocket соединение не установлено или закрыто.');
    }
};

// Функция для "Забираю"
const takeCards = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
            action: 'take_cards',
        }));
    } else {
        console.error('WebSocket соединение не установлено или закрыто.');
    }
};

// Проверка, можно ли подкинуть карту
const canThrowCard = (card) => {
    if (boardCards.value.length === 0) return true;

    // Проверяем все карты на доске, можно ли подкинуть карту с таким же номиналом
    for (let pile of boardCards.value) {
        if (pile.length > 0 && pile.some(boardCard => boardCard.value === card.value)) {
            return true;
        }
    }

    return false;
};

// Проверка, можно ли побить карту
const canBeat = (card, boardCard) => {
    if (card.suit === boardCard.suit) {
        return compareCards(card, boardCard) > 0;
    }
    if (card.suit === trumpCard.value.suit) {
        return boardCard.suit !== trumpCard.value.suit;
    }
    return false;
};

// Сравнение карт
const compareCards = (a, b) => {
    const valuesOrder = { "6": 1, "7": 2, "8": 3, "9": 4, "10": 5, "J": 6, "Q": 7, "K": 8, "A": 9 };
    return valuesOrder[a.value] - valuesOrder[b.value];
};

// Функция сортировки карт в руке игрока
const sortPlayerHand = () => {
    // Проверяем, что у игрока есть карты в руке, прежде чем сортировать
    if (playerHand.value && playerHand.value.length > 0) {
        playerHand.value.sort((a, b) => {
            if (a.suit === trumpCard.value.suit && b.suit !== trumpCard.value.suit) return 1;
            if (a.suit !== trumpCard.value.suit && b.suit === trumpCard.value.suit) return -1;
            return compareCards(a, b);
        });
    } else {
        console.log("У игрока нет карт в руке для сортировки.");
    }
};


const canPlayerThrowCard = () => {
  const nextPlayerID = getNextPlayerID(players.value, defenderId.value);
  console.log('Проверка canPlayerThrowCard: playerId:', playerId.value, 'attackerId:', attackerId.value, 'nextPlayerID:', nextPlayerID, 'allCardsBeaten:', allCardsBeaten.value);
  return playerId.value === attackerId.value || playerId.value === nextPlayerID;
};

// Функция для нахождения следующего игрока после защищающегося
const getPlayerWhoCanThrow = (players, defenderId) => {
  const defenderIndex = players.findIndex(player => player.player_id === defenderId);
  
  if (defenderIndex === -1) return null;

  // Индекс следующего игрока после защищающегося
  const nextPlayerIndex = (defenderIndex + 1) % players.length;
  return players[nextPlayerIndex];
};

// Использование функции для проверки, может ли текущий игрок подкидывать карты
const canThrowIcon = (playerId) => {
  const playerWhoCanThrow = getPlayerWhoCanThrow(players.value, defenderId.value);
  return playerId === playerWhoCanThrow?.player_id;
};


const allCardsBeaten = computed(() => {
  // Проверяем, есть ли карты на столе и побиты ли они все
  return boardCards.value.every(pile => pile.length > 1);
});

const getDeckCardStyle = (index) => {
  const spacing = 0.5  ; // Меньшее смещение для лучшего отображения
  const offset = index * spacing ; // Смещение карт
  return {
    top: `${offset}px`, // Смещение вниз
    left: `${offset}px`, // Смещение вправо
    zIndex: index, // Порядок наложения карт
    transform: `rotate(${index * 0}deg)` // Легкий поворот для визуального эффекта
  };
};

const getDeckCardStyle_1 = (index) => {
  const spacing = 0.9  ; // Меньшее смещение для лучшего отображения
  const offset = index * spacing ; // Смещение карт
  return {
    top: `${offset}px`, // Смещение вниз
    left: `${offset}px`, // Смещение вправо
    zIndex: index, // Порядок наложения карт
    transform: `rotate(${index * 40}deg)` // Легкий поворот для визуального эффекта
  };
};

const toggleSortOrder = () => {
  playerHand.value.reverse();
};

const calcAngle = (numCards) => {
  const maxAngle = 20;
  return Math.min(maxAngle / numCards, 5);
};


onMounted(() => {
    if (!roomId.value) {
        roomId.value = localStorage.getItem('fool_room_id');
    } else {
        localStorage.setItem('fool_room_id', roomId.value);
    }

    if (!playerId.value) {
        playerId.value = localStorage.getItem('fool_player_id');
    } else {
        localStorage.setItem('fool_player_id', playerId.value);
    }

    connectWebSocket();
});


watch(boardCards, (newVal) => {
  console.log('Текущие карты на столе (boardCards):', newVal);
  console.log('Проверка allCardsBeaten:', allCardsBeaten.value);
});




const sortedPlayers = computed(() => {
  const attackerIndex = players.value.findIndex(player => player.player_id === attackerId.value);
  const defenderIndex = players.value.findIndex(player => player.player_id === defenderId.value);

  // Если мы не нашли атакующего или защищающегося, возвращаем массив игроков как есть
  if (attackerIndex === -1 || defenderIndex === -1) {
    return players.value;
  }

  // Определяем порядок для остальных игроков
  let sortedArray = [];
  
  // Добавляем сначала атакующего, затем защищающегося
  sortedArray.push(players.value[attackerIndex]);  // Сначала атакующий
  sortedArray.push(players.value[defenderIndex]);  // Затем защищающийся
  
  // Оставшиеся игроки (подкидывающие)
  const remainingPlayers = players.value.filter(
    (player, index) => index !== attackerIndex && index !== defenderIndex
  );

  // Добавляем оставшихся игроков
  sortedArray = [...sortedArray, ...remainingPlayers];

  return sortedArray;
});


</script>

















<template>
  <GameLayout name-game="">
    <div class="containerFormCreate">
      <!-- Модальное окно -->
      <div v-if="showModal" class="modal">
        <p>{{ modalMessage }}</p>
      </div>

      <div>
        <!-- Ожидание подключения игроков -->
        <div v-if="!gameStarted && players.length < requiredPlayers" class="waiting-room">
          <h2>{{ $t('games.fool.waitingForPlayers') }}</h2>
          <p>{{ $t('games.fool.playersInRoom') }}: {{ players.length }}/{{ requiredPlayers }}</p>
          <div class="players-container">
            <div v-for="player in sortedPlayers" :key="player.player_id" class="player">
                <!-- <img :src="player.player_photo || '/default-avatar.png'" :alt="player.player_name" class="player-avatar"> -->
              <p>{{ player.player_name }}</p>
              <span :class="{ 'voted': endTurnVotes[player.player_id], 'not-voted': !endTurnVotes[player.player_id] }">
                <i class="fas fa-check-circle"></i>
              </span>
            </div>
          </div>
          <TelegramShareButton :url="url_share" :text="$t('games.fool.joinGame')"></TelegramShareButton>
        </div>

        <!-- Игра началась -->
        <div v-else-if="gameStarted" class="game-room">
          <!-- Игроки -->
          <div class="players-container">
            <div 
              v-for="player in sortedPlayers" 
              :key="player.player_id" 
              :class="['player', { 'current-player': player.player_id === playerId, 'custom-class': player.player_id === playerId }]"
            >
              <div>
                <p @click="isSpecialCase = !isSpecialCase">
                  {{ player.player_name }} 
                  <span class="red" v-if="player.player_id === defenderId"> <i class="fa-light fa-shield"></i></span>
                  <span class="blue" v-else-if="player.player_id === attackerId"><i class="fa-solid fa-person-walking"></i></span>
                  <span v-if="player.player_id !== defenderId && player.player_id !== attackerId && canThrowIcon(player.player_id)" class="yellow">
                    <i class="fa-light fa-shovel"></i>
                  </span>

                 
                </p>

                <!-- Отображаем карты рубашкой вверх веером, если это не текущий игрок и у него есть карты -->
                <div v-if="player.player_id !== playerId && player.hand_size > 0" class="card-back-container">
                  <div
                    v-for="n in player.hand_size"
                    :key="n"
                    class="card-back"
                    :style="{ transform: `rotate(${calcAngleForOpponentCards(n, player.hand_size)}deg)` }"
                  >
                    <div class="card-back-grey"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Козырь и колода слева -->
          <div class="deck-area">
            <div class="discard">
              <div class="card-fool deck-card" v-for="index in discardCount" :key="index" :style="getDeckCardStyle_1(index)"></div>
            </div>

            <!-- Показываем карты в колоде, если они есть -->
            <div v-if="deckSize > 1" class="deck">
              <div class="card-fool deck-card" v-for="index in deckSize" :key="index" :style="getDeckCardStyle(index)"></div>
            </div>

            <!-- Показываем последнюю карту (козырную карту) -->
            <div v-if="deckSize > 0" class="trump-card">
              <div class="card-fool">
                <p>{{ trumpCard.value }}</p>
                <p class="suit" :class="trumpCard.suit">{{ getSuitSymbol(trumpCard.suit) }}</p>
              </div>
            </div>

            <!-- Когда колода пуста, отображаем просто иконку масти козыря -->
            <div v-if="isDeckNull" class="trump-icon">
              <p class="suit" :class="trumpCard.suit">{{ getSuitSymbol(trumpCard.suit) }}</p>
            </div>

            <div class="scoreboard">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="player in sortedPlayers" :key="player.player_id">
                    <td>{{ player.player_name }}</td>
                    <td>{{ player.score }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="top-view-players">
              <div class="top-view-element" v-for="player in sortedPlayers" :key="player.player_id">
                    <p >
                    
                      <span class="red" v-if="player.player_id === defenderId">   {{ player.player_name }}  <i class="fa-light fa-shield"></i></span>
                      
                      <span class="blue" v-else-if="player.player_id === attackerId">  {{ player.player_name }} <i class="fa-solid fa-person-walking"></i> <i class="fa-thin fa-arrow-right"></i> </span>
                      <span v-if="player.player_id !== defenderId && player.player_id !== attackerId && canThrowIcon(player.player_id)" class="yellow">
                        <i class="fa-sharp fa-thin fa-arrow-left"></i> <i class="fa-light fa-shovel"> </i>   {{ player.player_name }} 
                      </span>

                    
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Игровое поле -->
          <div class="board">
            <div class="cards">
              <div v-for="(pile, index) in boardCards" :key="index" class="pile">
                <div 
                  v-for="card in pile" 
                  :key="card.value + card.suit" 
                  class="card-fool" 
                  :class="[card.suit, { selected: selectedBoardCard && selectedBoardCard.suit === card.suit && selectedBoardCard.value === card.value }]"
                  @click="selectBoardCardToBeat(card)"
                >
                  <p>{{ card.value }}</p>
                  <p class="suit">{{ getSuitSymbol(card.suit) }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Карты игрока внизу -->
          <div class="player-cards-container">
            <div class="cards">
              <div
                v-for="(card, index) in playerHand"
                :key="index"
                class="card-fool"
                :class="[card.suit, { selected: selectedCard && selectedCard.suit === card.suit && selectedCard.value === card.value }]"
                @click="isDefending ? selectCardToBeat(card) : makeMove(card)"
                :style="{'--index': index, '--num-cards': playerHand.length, '--angle': calcAngle(playerHand.length)}"
              >
                <p>{{ card.value }}</p>
                <p class="suit">{{ getSuitSymbol(card.suit) }}</p>
              </div>
            </div>

            <!-- Полоска с кнопками -->
            <div class="actions-bar">
              <div class="left-buttons">
                <div v-if="actionButtonsVisible" class="def_button">
                  <button class="btn-grad" 
                    v-if="(playerId === attackerId || (canPlayerThrowCard() && !isDefending)) && allCardsBeaten" 
                    @click="endTurn"
                  >
                    {{ $t('games.fool.retreat') }}
                  </button>

                  <div v-if="isDefending">
                    <button class="btn-grad" @click="takeCards">{{ $t('games.fool.takeCards') }}</button>
                    <button class="btn-grad" v-if="defenderCanEndTurn" @click="endTurn">{{ $t('games.fool.retreat') }}</button>
                  </div>
                </div>
              </div>

              <div class="right-buttons">
                <button class="sort-button" @click="toggleSortOrder"><i class="fa-sharp fa-thin fa-right-left"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </GameLayout>
</template>




<style scoped>

.def_button {
width: 100%;
display: flex;

}

.btn-grad {
width: 150px;
margin-left: 30px;
}
.players-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 60px;

}



.deck-area {
  position: fixed;
  top: 40%;
  left: 20px;
}

.deck {
  display: flex;
  flex-direction: column;
}


.player-cards-container {
  position: fixed;
  bottom: 120px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.actions-bar {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.right-buttons {
  margin-right: 10px;
}


.sort-button {
  background-color: #f3f3f3;
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.current-player {
  position: fixed;
  top: 6px; /* Размещаем информацию внизу экрана */
  left: 15%;
  transform: translateX(-50%);
  text-align: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.top-view-def {
  position: fixed;
  top: 6px; /* Размещаем информацию внизу экрана */
  right: 7%;
  transform: translateX(-50%);
  text-align: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.formCreate {
  max-width: 900px;
}

.waiting-room, .game-room {
  height: auto;
  text-align: center;
}

.players-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deck-and-trump {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.deck {
  position: relative;
  margin-right: 15px;
}


.discard-pile {
  margin-left: 20px;
}

.discard-pile h3 {
  margin-bottom: 5px;
}

.discard-card {
  width: 60px;
  height: 90px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border: 2px solid #000;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.player-hand-container {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.current-player-name {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.player-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.card-fool.selected {
  transform: scale(1.2);
}

.hearts {
  color: red !important;
}

.diamonds {
  color: red !important;
}

.clubs {
  color: black;
}

.spades {
  color: black;
}

.action-buttons-left {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
}

.action-buttons-left button {
  margin-bottom: 10px;
}

.action-buttons button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  transition: background-color 0.3s;
}

.action-buttons button:hover {
  background-color: #0056b3;
}


.deck-container {
  position: fixed;
  top: 35%;
  left: 20px;
}

.deck {
  display: flex;
  flex-direction: column;
}

.trump-card {
  margin-top: -110px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.card-fool {
  width: 60px;
  height: 90px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  border: 2px solid #000;
  font-size: 1.7rem;
}

.deck-card {
  position: absolute;
 
  border: .5px solid rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  background: linear-gradient(to bottom, #2F70AF 50%, #F7C815 50%);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  transition: transform 0.2s;
}

.suit {
  font-size: 2.5rem;
}
.discard-pile {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

.card-back-container {
  display: flex;
  justify-content: center; /* Центрируем карты по горизонтали */
  align-items: center; /* Центрируем карты по вертикали */
  position: relative;
  width: auto;
  height: 100px; /* Высота контейнера для карт */
  margin: 0 auto;
}

.card-back {
  width: 50px;  /* Ширина карты */
  height: 80px; /* Высота карты */
  background-color: grey; /* Временно серая рубашка карты */
  border-radius: 5px;
  border: 1px solid black;
  position: absolute; /* Абсолютное позиционирование для накладывания карт друг на друга */
  transform-origin: bottom center;
  transition: transform 0.2s ease;
}

.card-back-grey {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #F7C815 0%, rgba(45,123,253,1) 100%);
  border-radius: 5px;
}

.trump-icon {
position: relative;
bottom: 75px;
z-index: 0;
}

.red {
  background-color: IndianRed;
  padding: 7px;
  border-radius: 12px;
  color: #f3f3f3;
  font-size: larger;

}

.yellow {
  background-color: #F7C815;
  padding: 7px;
  border-radius: 12px;
  color: #333;
  font-size: larger;
}

.blue {
  background-color: #2F70AF;
  padding: 7px;
  border-radius: 12px;
  color: #f3f3f3;
  font-size: larger;


}

.scoreboard {

  position: relative;
  top: 100px;

}

.discard {
  position: fixed;
  top: 80px;
  left: 30px;
}


.top-view-players {
  position: fixed;
  display: flex;
  right: 5%;
  top:15px;
  margin: 1px;
}

.top-view-element {
margin-right: 15px;

}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
  /* Уменьшаем размер карт для игрока */

  .discard {
  top:70px;
  left: 20px;
}

  .suit {
  font-size: 1.1rem;
}

  .deck-card {
    width: 40px;
    height: 60px;
  }

  .card-fool {
    width: 40px;
    height: 60px;
    font-size: 1rem;  

  }

  /* Размер карт на столе */
  .pile .card-fool {
    width: 40px;
    height: 60px;
  }

  /* Расположение колоды карт */
  .deck {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 5%;
    top: 20%;
  }

  /* Размер и расположение козырной карты */
  .trump-card {
    width: 40px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 5%;
    z-index: 2;
  }

  .trump-icon {
    left: -14px;
    bottom: 35px;
  }
  /* Стили для зоны действий игроков (например, кнопки "Забираю" и "Отбой") */
  .actions-bar {
    bottom: -65px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }

  .btn-grad {
    width: 100px;
    margin: 5px;
    font-size: small;
  }

  /* Стили для отображения карт противников */
  .card-back-container {
    width: auto;
  }

  .card-back {
    width: 40px;
    height: 60px;
  }

  /* Оптимизация для таблицы очков */
  .scoreboard {
    position: relative;
    top: 80px;
    font-size: 0.9rem;
  }
  .players-container {
    font-size: 0.9rem;
  }
}

</style>
